import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'moment';
import { DateFilter, DateFilterContext, formatDateInTitle, getDateFilterDate } from './DateFilter'
import { sum, safeDivide, formatCurrency } from '../Utils'

/** Shows the main order details page. */
export class Orders extends Component {

    static monthNames = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    static contextType = DateFilterContext;

    constructor(props) {
        super(props);

        this.state = {
            dayOrders: [],
            loading: true,
            otherStats: {}
        };

        this.handleDateChange = this.handleDateChange.bind(this);
    }

    componentDidMount() {
        this.populateOrderData();
    }

    /** Populates the data for this page. */
    async populateOrderData() {
        const date = getDateFilterDate(this.context, true);
        const response = await fetch(`/api/orders/main-stats?month=${date.month}&year=${date.year}`);
        const data = await response.json();
        this.setState({ dayOrders: data, loading: false });

        const responseSums = await fetch(`/api/orders/main-stats-totals?month=${date.month}&year=${date.year - 1}`);
        const dataSums = await responseSums.json();
        this.setState({ dayOrderSums: dataSums });

        const responseOther = await fetch(`/api/orders/other-stats?month=${date.month}&year=${date.year}`);
        const dataOther = await responseOther.json();
        this.setState({ otherStats: dataOther });
    }
    /**
     * Formats a percentage string by dividing a number or the sum of a set of data by another number.
     * @param {any} data - A number or an array of objects.
     * @param {any} keyOrDivider - A number or the key to divide the total by.
     */
    static formatPercent(data, keyOrDivider) {
        if (typeof keyOrDivider === "string") {
            return Array.isArray(data) ?
                Orders.formatPercent(sum(data, keyOrDivider), sum(data, 'periodTotal')) :
                Orders.formatPercent(data[keyOrDivider], data.periodTotal);
        }

        return (safeDivide(data, keyOrDivider) * 100).toFixed(2);
    }
    /**
     * Renders the top table.
     * @param {any} items - A list of items to display in the top table.
     * @param {boolean} monthMode - true if by month, false if by day.
     */
    static renderDayOrderRows(items, monthMode) {
        return (
            <tbody>
                {items.map(day =>
                    <tr key={day.dateNumber} className={day.isWeekend ? "table-secondary" : ""}>
                        <th className="text">{monthMode ? Orders.monthNames[day.dateNumber] : day.dateNumber}</th>
                        <td>
                            {monthMode ? day.periodTotal :
                                <NavLink to={`/orders/day/${Moment(day.date).format('YYYYMMDD')}`}>{day.periodTotal}</NavLink>
                            }
                        </td>
                        <td>{Orders.formatPercent(day, 'toys')}</td>
                        <td>{Orders.formatPercent(day, 'balloons')}</td>
                        <td>{Orders.formatPercent(day, 'chocolates')}</td>
                        <td>{Orders.formatPercent(day, 'sizeUpgrade')}</td>
                        <td>{formatCurrency(day.orderValue)}</td>
                        <td>{safeDivide(day.orderValue, day.periodTotal).toFixed(2)}</td>
                    </tr>
                )}
            </tbody>
        );
    }
    /**
     * Formats the percentage change in the totals in the footer of the top table.
     * @param {Array} data - List of data for the period.
     * @param {any} previous - Sums of the previous period.
     * @param {any} key - Attribute within the data to get the change of.
     * @param {any} isSumMode - Whether to calculate the difference in sum. If false, divides the value by the period total.
     */
    static formatFooterPercent(data, previous, key, isSumMode) {
        if (!previous) return;

        let text;
        let css = "success";

        // if the previous value is available, divide it. otherwise, show the infinity symbol
        if (previous[key]) {
            let current = sum(data, key);
            let previousValue = previous[key];

            // if average mode, divide the current and previous values with the number of orders in the period
            if (!isSumMode) {
                current /= sum(data, 'periodTotal');
                previousValue /= previous['periodTotal'];
            }

            // format the display number
            text = current / previousValue - 1;
            text = (text * 100).toFixed(2);

            // if change is negative, make text red. otherwise, append a + to the number
            if (current < previousValue) css = "danger";
            else text = "+" + text;
        } else {
            text = "+∞";
        }
        
        return <small className={`text-${css}`}>{text}%</small>;
    }
    /**
     * Renders the totals row in the top table.
     * @param {Array} data - List of period data.
     */
    static renderDayOrderTotals(data, previous) {
        return (
            <tfoot>
                <tr>
                    <th>Total</th>
                    <td>{sum(data, 'periodTotal').toLocaleString(undefined)}<br />{this.formatFooterPercent(data, previous, 'periodTotal', true)}</td>
                    <td>{Orders.formatPercent(data, 'toys')}<br />{this.formatFooterPercent(data, previous, 'toys')}</td>
                    <td>{Orders.formatPercent(data, 'balloons')}<br />{this.formatFooterPercent(data, previous, 'balloons')}</td>
                    <td>{Orders.formatPercent(data, 'chocolates')}<br />{this.formatFooterPercent(data, previous, 'chocolates')}</td>
                    <td>{Orders.formatPercent(data, 'sizeUpgrade')}<br />{this.formatFooterPercent(data, previous, 'sizeUpgrade')}</td>
                    <td>{formatCurrency(sum(data, 'orderValue'))}<br />{this.formatFooterPercent(data, previous, 'orderValue', true)}</td>
                    <td>{safeDivide(sum(data, 'orderValue'), sum(data, 'periodTotal')).toFixed(2)}<br />{this.formatFooterPercent(data, previous, 'orderValue')}</td>
                </tr>
            </tfoot>
        );
    }
    /**
     * Renders the summary cards for each statistic.
     * @param {Array} data - The data to display.
     * @param {string} cardLabel - The string to show at the top of the table labeling the data.
     * @param {string} columnLabel - The string to show for the leftmost column.
     */
    static renderSalesByElements(data, cardLabel, columnLabel) {
        if (!(data && data.length)) return null;

        return (
            <div className="card mb-4 shadow">
                <div className="card-header">Top {cardLabel}</div>
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{columnLabel}</th>
                                <th>Sales</th>
                                <th>Sales %</th>
                                <th>AOS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(item =>
                                <tr key={item.text}>
                                    <th dangerouslySetInnerHTML={{ __html: item.text }}></th>
                                    <td>{item.salesCount}</td>
                                    <td>{item.salesPercentage.toFixed(1)}</td>
                                    <td>{item.averageOrderSize.toFixed(2)}</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    /** Handles the event when the date filter changes. */
    handleDateChange() {
        this.setState({ otherStats: null });
        this.setState({ loading: true }, this.populateOrderData);
    }

    render() {
        let dayOrderRows = this.state.loading ?
            <tbody><tr><td rowSpan="8">Loading...</td></tr></tbody> :
            Orders.renderDayOrderRows(this.state.dayOrders.days, this.context.date.month === - 1);
        let dayOrderTotals = this.state.loading ? null :
            Orders.renderDayOrderTotals(this.state.dayOrders.days, this.state.dayOrderSums);

        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Orders in {formatDateInTitle(this.context.date)}</h1>
                    <NavLink className="btn btn-primary" to="/orders/transmit">Transmit Orders</NavLink>
                </div>
                <DateFilter onDateChange={this.handleDateChange} includeAllYear={true}/>
                <div className="card shadow mb-4">
                    <div className="card-header">Summary</div>
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>{this.state.dayOrders.monthOrDayLabel}</th>
                                    <th>Order #</th>
                                    <th>Toys %</th>
                                    <th>Balloons %</th>
                                    <th>Chocolates %</th>
                                    <th>Premium %</th>
                                    <th>Total $</th>
                                    <th>AOS $</th>
                                </tr>
                            </thead>
                            {dayOrderRows}
                            {dayOrderTotals}
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        {Orders.renderSalesByElements(this.state.otherStats?.sourceStates, "Source States", "State")}
                        {Orders.renderSalesByElements(this.state.otherStats?.sourceCities, "Source Cities", "City")}
                        {Orders.renderSalesByElements(this.state.otherStats?.sourceZip, "Source Postcodes", "Postcode")}
                        {Orders.renderSalesByElements(this.state.otherStats?.deliveryMethods, "Delivery Methods", "Method")}
                        {Orders.renderSalesByElements(this.state.otherStats?.categories, "Categories", "Category")}
                        {Orders.renderSalesByElements(this.state.otherStats?.coupons, "Coupons", "Coupon")}
                    </div>
                    <div className="col-lg-6">
                        {Orders.renderSalesByElements(this.state.otherStats?.destinationStates, "Destination States", "State")}
                        {Orders.renderSalesByElements(this.state.otherStats?.destinationCities, "Destination Cities", "City")}
                        {Orders.renderSalesByElements(this.state.otherStats?.paymentMethods, "Payment Methods", "Method")}
                        {Orders.renderSalesByElements(this.state.otherStats?.occasions, "Occasions", "Occasion")}
                    </div>
                </div>
            </>
        );
    }
}