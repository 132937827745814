import React, { useState } from 'react';

export function TransmitOrders() {
    const [fromID, setFromID] = useState(null);
    const [toID, setToID] = useState(null);
    const [transmittedCount, setTransmittedCount] = useState(null);
    const [transmitted, setTransmitted] = useState(false);

    const transmit = async () => {
        var response = await fetch(`/api/orders/transmit?from=${fromID}&to=${toID}`, { method: "POST" });
        var data = await response.json();
        if (data) {
            setTransmittedCount(data);
            setTransmitted(true);
        }
    }

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Transmit Orders Manually</h1>
            </div>
            <div className="card shadow mb-4">
                {transmitted &&
                    <div className="alert alert-success">
                        {transmittedCount} orders transmitted.
                        <button className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    </div>
                }
                <div className="card-body">
                    <form className="form-inline mb-4">
                        <strong>Optional</strong>: Transmit orders between
                        <input type="text" className="form-control mx-2" onChange={e => setFromID(e.target.value)}></input>
                        and
                        <input type="text" className="form-control mx-2" onChange={e => setToID(e.target.value)}></input>
                    </form>
                    <button onClick={transmit} className="btn btn-primary">Transmit</button>
                </div>
            </div>
        </>
    );
}