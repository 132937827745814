import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { getImageUrl } from "../Utils.js";

export function Images() {
    const [images, setImages] = useState(false);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);

    const renderImages = () => {
        return (
            images.map(image =>
                <div key={image.id} className="card col-lg-4">
                    <div className="card-body text-center">
                        <NavLink to={`/products/images/edit-size/${image.id}`}>
                            <img className="mb-2" src={getImageUrl(image.mainID, "220x200")} alt=""/>
                            <img className="mb-2" src={getImageUrl(image.alternateID, "220x200")} alt=""/><br/>
                            {image.mainID}
                        </NavLink>
                    </div>
                </div>
            )
        );
    }

    const handlePageChange = (i) => {
        if (i < 0) i = 0;
        else if (i > pageCount) i = pageCount - 1;
        setPage(i);
    }

    const renderPager = () => {
        let result = [];

        for (let i = 0; i < pageCount; ++i) {
            result.push(
                <li className={`page-item ${i === page ? "active" : ""}`} key={i}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>{i + 1}</button>
                </li>
            )
        }

        return result;
    }

    useEffect(() => {
        async function getData() {
            var response = await fetch("/api/products/image-sizes?page=" + page);
            var { images, pageCount } = await response.json();
            setImages(images);
            setPageCount(pageCount);
        }

        getData();
    }, [page]);

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Image Sizes</h1>
                <button className="btn btn-primary dropdown-toggle" id="actionButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button>
                <div className="dropdown-menu" aria-labelledby="actionButton">
                    <NavLink className="dropdown-item" to="/products/images/edit-size">Add Size</NavLink>
                    <NavLink className="dropdown-item" to="/products/images/upload">Upload</NavLink>
                </div>
            </div>
            <div className="row gx-2">
                {images ? renderImages() : "Loading..."}
            </div>
            <div className="card shadow">
                <div className="card-body">
                    {pageCount > 0 && 
                        <ul className="pagination">
                            <li className="page-item"><button className="page-link" onClick={() => handlePageChange(page - 1)}>Previous</button></li>
                            {renderPager()}
                            <li className="page-item"><button className="page-link" onClick={() => handlePageChange(page + 1)}>Next</button></li>
                        </ul>
                    }
                </div>
            </div>
        </>
    );
}