import React, { Component } from 'react';
import Moment from 'moment';
import { DateFilter, DateFilterContext, getDateFilterDate, formatDateInTitle } from './DateFilter';

export class ErrorReport extends Component {
    static contextType = DateFilterContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            errorDetails: {},
            showKeyEntry: false,
            keySuccess: null,
            keyError: null
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.toggleKeyEntry = this.toggleKeyEntry.bind(this);
        this.loadKeys = this.loadKeys.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        // get the date. but if all year is requested, default back to january
        const date = getDateFilterDate(this.context);
        const response = await fetch(`api/errors?year=${date.year}&month=${date.month}`);
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }
    async loadMoreData(item) {
        const response = await fetch("api/errors/detail", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ latest: item.latest, message: item.message })
        });

        const data = await response.json();
        data.open = true;

        this.setState({
            errorDetails: {
                ...this.state.errorDetails,
                [item.hash]: data
            }
        });
    }
    renderItems(items) {
        const details = this.state.errorDetails;

        return items.map(item =>
            <tr key={item.hash}>
                <td>{Moment(item.latest).format("MMM D, HH:mm")}</td>
                <td>{item.count}</td>
                <td>{item.code}</td>
                <td>{item.message}
                    <span className="badge badge-info ml-2" onClick={() => this.handleMoreInfo(item)}>more</span>
                    {details[item.hash] && details[item.hash].open &&
                        <div className="mt-2">
                            <dt>Location</dt>
                            <dd>{details[item.hash].location}</dd>
                            {details[item.hash].referrer &&
                                <>
                                    <dt>Referrer</dt>
                                    <dd>{details[item.hash].referrer}</dd>
                                </>
                            }
                            {details[item.hash].stackTrace &&
                                <>
                                    <dt>Stack trace</dt>
                                    <dd style={{ whiteSpace: 'pre-wrap' }}>{details[item.hash].stackTrace}</dd>
                                </>
                        }
                        </div>
                     }
                </td>
            </tr>
        );
    }
    handleDateChange(date) {
        this.setState({ date: date }, this.loadData);
    }
    handleMoreInfo(item) {
        this.loadMoreData(item);
    }
    toggleKeyEntry() {
        this.setState({ showKeyEntry: true });
    }
    /** Loads the private encryption key into the key store. */
    async loadKeys() {
        this.setState({ keySuccess: null, keyError: null });

        var response = await fetch("/api/errors/load-keys");
        var body = await response.text();
        if (body && body.length) this.setState({ keyError: body });
        else this.setState({ keySuccess: true });
    }
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Errors in {formatDateInTitle(this.context.date)}</h1>
                </div>
                <DateFilter onDateChange={this.handleDateChange} />
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Latest</th>
                                    <th>Count</th>
                                    <th>Code</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading ? <tr><td colSpan="5">Loading...</td></tr> :
                                    this.renderItems(this.state.data)}
                            </tbody>
                        </table>
                    </div>
                </div>
                {!this.state.showKeyEntry &&
                    <p className="text-center"><a onClick={this.toggleKeyEntry}>Enter Keys</a></p>
                }
                {this.state.showKeyEntry &&
                    <div className="card shadow mb-4">
                        <div className="card-body">
                            {this.state.keySuccess &&
                                <div className="alert alert-success">Keys have been entered.</div>
                            }
                            {this.state.keyError &&
                                <div className="alert alert-danger">There were errors entering the keys: {this.state.keyError}</div>
                            }
                            <button onClick={this.loadKeys} className="btn btn-primary">Load Keys</button>
                        </div>
                    </div>
                }
            </>
        );
    }
}