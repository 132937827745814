import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    render() {
        return (
            <div id="wrapper">
                <NavMenu onLogout={this.props.onLogout} />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Container fluid className="pt-4">
                            {this.props.children}
                        </Container>
                    </div>
                </div>
            </div>
        );
    }
}
