/**
 * Sums a list of items' attribute key. That is, for a list of items, sum the attribute "key" of each item.
 * @param {Array} items - The list of items to sum.
 * @param {string} key - The key to read and sum.
 */
export function sum(items, key) {
    return items.reduce(function (r, a) {
        return r + a[key];
    }, 0);
}
/**
 * Safely divides two numbers. If the divisor is 0, returns 0.
 * @param {any} numeral - The number to divide with.
 * @param {any} divisor - The number to divide by.
 */
export function safeDivide(numeral, divisor) {
    if (divisor === 0) return 0;
    return numeral / divisor;
}
/**
 * Gets a product image URL with the ID and size provided.
 * @param {string} id - The ID of the image.
 * @param {string} size - The size of the image in widthxheight format.
 */
export function getImageUrl(id, size) {
    return `https://www.jackmans.com/images/products/${id}/${size}/jm-product.jpg`;
}
/**
 * Get a number formatted in currency form, with digit grouping and two decimal places.
 * @param {any} n
 */
export function formatCurrency(n) {
    return n.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}