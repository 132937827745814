import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Moment from 'moment';

export function OrderTransmits() {
    // gets the date from the URL, if editing an existing pair
    const { date } = useParams();
    // where the pair data resides
    const [data, setData] = useState(null);

    const getCssClass = (status) => {
        switch (status) {
            case "DUPLICATE":
            case "CLEAR":
            case "RETRANSMIT":
                return "table-warning";
            case "SUCCESS":
                return "table-success";
            case "FAIL":
                return "table-danger";
            default:
                return "";
        }
    };

    const renderRows = () => {
        if (!data.records.length) {
            return <tbody><tr><td colSpan="3">No transmissions on this day.</td></tr></tbody>;
        }
        return (
            <tbody>
                {data.records.map((d, index) =>
                    <tr key={index} className={getCssClass(d.status)}>
                        <td>{Moment(d.timeStamp).format("h:mm:ss a")}</td>
                        <td>{d.status}</td>
                        <td>{d.message}</td>
                    </tr>
                )}
            </tbody>
        );
    };

    useEffect(() => {
        async function getData() {
            let response = await fetch(`/api/orders/transmits?date=${date}`);
            let data = await response.json();
            setData(data);
        }

        getData();
    }, [date]);

    const momentDate = Moment(date);

    return (
        <div>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Order Transmits on {momentDate.format("MMMM D, yyyy")}</h1>
            </div>
            {data &&
                <div className="card shadow mb-4">
                    <div className="card-body">
                        {data.successCount} successes<br />
                        {data.retransmitCount} retransmits<br />
                        {data.failureCount} failures<br />
                        {data.clearCount} clears
                        {data.duplicates.length > 0 &&
                            <>
                                <br />{data.duplicates.length} duplicates<br/>
                                {data.duplicates.join(", ")}
                            </>
                        }
                    </div>
                </div>
            }
            <div className="card shadow mb-4">
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Time</th>
                                <th>Status</th>
                                <th>Message</th>
                            </tr>
                        </thead>
                        {data ? renderRows() :
                            <tbody>
                                <tr>
                                    <td colSpan="3">Loading...</td>
                                </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        </div>
    );
}