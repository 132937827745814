import React, { useState } from 'react';
import DropzoneComponent from 'react-dropzone-component';

import './dropzone.min.css';
import 'react-dropzone-component/styles/filepicker.css'

export function UploadImages() {
    const [target, setTarget] = useState(null);
    const [dzConfig, setDzConfig] = useState({ showFiletypeIcon: true });
    const [djsConfig, setDjsConfig] = useState({
        uploadMultiple: false,
        timeout: 15 * 60 * 1000,
        parallelUploads: 1
    });

    // fires when the target has changed
    const handleOptionChange = (e) => {
        const target = e.target.value;
        setTarget(target);

        // update the component's configuration
        const postUrl = "/api/products/upload-images?target=" + target;
        const iconFiletypes = target === "process" ?
            ['.jpg', '.jpeg', '.png', '.heic', '.heif'] : ['.jpg'];
        setDzConfig({ ...dzConfig, iconFiletypes, postUrl });

        // update the inner dropzone object's configuration
        const acceptedFiles = target === "process" ? "image/*" : "image/jpeg";
        setDjsConfig({ ...djsConfig, acceptedFiles });
    }

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Upload Images</h1>
            </div>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <div className="form-group">
                        <div className="form-check">
                            <input type="radio" name="type" id="type-process" className="form-check-input" value="process" onChange={handleOptionChange} checked={target === "process"}></input>
                            <label className="form-check-label" htmlFor="type-process">For further processing</label>
                        </div>
                        <div className="form-check">
                            <input type="radio" name="type" id="type-ready" className="form-check-input" value="ready" onChange={handleOptionChange} checked={target === "ready"}></input>
                            <label className="form-check-label" htmlFor="type-ready">Renamed and ready to display on the website</label>
                        </div>
                    </div>
                    {target && <DropzoneComponent config={dzConfig} djsConfig={djsConfig} />}
                </div>
            </div>
        </>
    );
}