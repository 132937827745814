import React, { Component } from 'react';
import Moment from 'moment';

export class Inquiries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: null,
            loading: true
        }
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        const response = await fetch('api/customers/inquiries');
        const data = await response.json();
        this.setState({ items: data, loading: false });
    }
    static renderItems(items) {
        return items.map((item, index) =>
            <div className="card shadow mb-4" key={index}>
                <div className="card-body">
                    <div style={{ whiteSpace: 'pre-wrap' }}>{item.message}</div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <dt className="col-3">From</dt>
                        <dd className="col-9">{item.name} ({item.email})</dd>
                        <dt className="col-3">Created</dt>
                        <dd className="col-9">{Moment(item.created).format("LLL")}</dd>
                        {item.orderNumber &&
                            <>
                                <dt className="col-3">Order number</dt>
                                <dd className="col-9">{item.orderNumber}</dd>
                            </>
                        }
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Last 20 Inquiries</h1>
                </div>
                {this.state.loading ?
                    <div className="card shadow mb-4">
                        <div className="card-body">Loading...</div>
                    </div> :
                    Inquiries.renderItems(this.state.items)
                }
            </>
        );
    }
}