import React, { useState } from 'react';

/**
 * The login screen.
 */
export default function Login(props) {
    const [error, setError] = useState(null);
    const [inputs, setInputs] = useState(null);

    // performs the login by sending the password to auth. the API should respond
    // with a cookie
    const login = async () => {
        setError(false);

        const fetchOptions = {
            method: "POST",
            body: JSON.stringify(inputs),
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            }
        };

        const response = await fetch("/api/auth/login", fetchOptions);
        const result = await response.json();
        if (result) {
            if (result.success) props.onLogin();
            else setError(result.error);
        }
        else setError("There was an error on the server.")
    }

    // updates state of the inputs
    const handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setInputs({
            ...inputs,
            [target.name]: value
        })
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                <div className="col-lg-6 d-none d-lg-block bg-login-image" style={{ backgroundImage: "url(/vendors/login.jpg)" }}></div>
                                <div className="col-lg-6">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                        </div>
                                        {error &&
                                            <div className="alert alert-danger">{error}</div>
                                        }
                                        <div>
                                            <div className="form-group">
                                                <input type="password" className="form-control form-control-user" name="password" placeholder="Password" onChange={handleInputChange}></input>
                                            </div>
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox small">
                                                    <input type="checkbox" className="custom-control-input" name="remember" id="remember" onChange={handleInputChange} />
                                                    <label className="custom-control-label" htmlFor="remember">Remember Me</label>
                                                </div>
                                            </div>
                                            <button className="btn btn-primary btn-user btn-block" onClick={login}>
                                                Login
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}