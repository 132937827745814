import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Moment from 'moment';

export class OrdersDay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.match.params.date,
            orders: null,
            loading: true
        }
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        const response = await fetch(`/api/orders/day?date=${this.props.match.params.date}`);
        const data = await response.json();
        this.setState({ orders: data, loading: false });
    }
    static renderRows(orders) {
        if (!(orders && orders.length)) return null;

        return (
            <tbody>
                {orders.map(order =>
                    <tr key={order.orderID}>
                        <td><NavLink to={`/orders/${order.orderID}`}>{order.orderID}</NavLink></td>
                        <td>{Moment(order.date).format("h:mm a")}</td>
                        <td>{order.email}</td>
                        <td>{order.amount}</td>
                    </tr>
                )}
            </tbody>
        );
    }
    render() {
        const date = Moment(this.props.match.params.date);

        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Orders on {date.format("MMMM D, yyyy")}</h1>
                    <NavLink className="btn btn-primary" to={`/orders/transmits/${this.props.match.params.date}`}>View Transmits</NavLink>
                </div>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Time</th>
                                    <th>Email</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            {OrdersDay.renderRows(this.state.orders)}
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}