import React, { Component } from 'react';
import Moment from 'moment';
import { getImageUrl } from '../Utils';

export class OrderDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            details: {},
            loading: true
        };
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        const response = await fetch(`/api/orders?id=${this.props.match.params.id}`);
        const data = await response.json();
        this.setState({ details: data, loading: false });
    }
    static formatAddress(address) {
        if (!address) return null;
        return `${address.street1}, ${address.city}, ${address.state} ${address.postcode}, ${address.country}`;
    }
    /**
     * Formats an amount to 2 decimal places. 0 if amount is unavailable.
     * @param {any} amount
     */
    formatAmount(amount) {
        return amount ? amount.toFixed(2) : "0.00";
    }
    render() {
        const details = this.state.details;

        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Order #{this.props.match.params.id}<br/>
                        <small className="text-muted">{Moment(details.created).format("LLL")}</small>
                    </h1>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card shadow mb-4">
                            <div className="card-header">Sender</div>
                            <div className="card-body">
                                <div className="row">
                                    <dt className="col-4">Name</dt>
                                    <dd className="col-8">{details.fromName}</dd>
                                    <dt className="col-4">Address</dt>
                                    <dd className="col-8">{details.fromAddress?.street1 ? OrderDetail.formatAddress(details.fromAddress) : details.fromAddress?.postcode}</dd>
                                    <dt className="col-4">Phone</dt>
                                    <dd className="col-8">{details.fromPhone}</dd>
                                </div>
                            </div>
                        </div>
                        {!details.isPickUp &&
                            <div className="card shadow mb-4">
                                <div className="card-header">Recipient</div>
                                <div className="card-body">
                                    <div className="row">
                                        <dt className="col-4">Name</dt>
                                        <dd className="col-8">{details.toName}</dd>
                                        <dt className="col-4">Address</dt>
                                        <dd className="col-8">{OrderDetail.formatAddress(details.toAddress)}</dd>
                                        <dt className="col-4">Phone</dt>
                                        <dd className="col-8">{details.toPhone ? details.toPhone : "n/a"}</dd>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="card shadow mb-4">
                            <div className="card-header">{details.isPickUp ? "Pickup" : "Delivery"}</div>
                            <div className="card-body">
                                <div className="row">
                                    <dt className="col-4">Date</dt>
                                    <dd className="col-8">{Moment(details.deliveryDate).format(details.isPickUp ? "LLL" : "LL")}</dd>
                                    <dt className="col-4">Occasion</dt>
                                    <dd className="col-8">{details.occasion}</dd>
                                    <dt className="col-4">Card Message</dt>
                                    <dd className="col-8" style={{ whiteSpace: 'pre-wrap' }}>{details.cardMessage}</dd>
                                    {details.specialInstructions &&
                                        <>
                                            <dt className="col-4">Special Instructions</dt>
                                            <dd className="col-8">{details.specialInstructions}</dd>
                                        </>
                                    }
                                 </div>
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card shadow mb-4">
                            <div className="card-header">Product</div>
                            <div className="card-body media">
                                {details.items && details.items.length &&
                                    <>
                                        <a href={`https://www.jackmans.com/products/${details.items[0].code}`}>
                                            <img src={getImageUrl(details.items[0].code, "220x220")} alt="" className="mr-2" />
                                        </a>
                                        <div className="media-body align-self-center">
                                            <h5><a href={`https://www.jackmans.com/products/${details.items[0].code}`}>
                                                {details.items[0].code}
                                            </a></h5>
                                            <p className="mb-0">{details.items[0].optionString}</p>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="card shadow mb-4">
                            <div className="card-header">Payment</div>
                            <div className="card-body">
                                <div className="row">
                                    <dt className="col-4">Card Number</dt>
                                    <dd className="col-8">{details.cardNumber ? details.cardNumber : "xxxx-xxxx-xxxx-" + details.cardEnds}</dd>
                                    {details.cardExpiry && 
                                        <><dt className="col-4">Card Expiry</dt><dd className="col-8">{details.cardExpiry}</dd></>
                                    }
                                    <dt className="col-4">Product Total</dt>
                                    <dd className="col-8">{this.formatAmount(details.grandTotal - details.discount - details.serviceFee - details.deliveryFee - details.salesTax - details.deliveryTax)}</dd>
                                    <dt className="col-4">Discount</dt>
                                    <dd className="col-8">{this.formatAmount(details.discount)}</dd>
                                    <dt className="col-4">Service Fee</dt>
                                    <dd className="col-8">{this.formatAmount(details.serviceFee)}</dd>
                                    <dt className="col-4">Delivery Fee</dt>
                                    <dd className="col-8">{this.formatAmount(details.deliveryFee)}</dd>
                                    <dt className="col-4">Sales Tax</dt>
                                    <dd className="col-8">{this.formatAmount(details.salesTax)}</dd>
                                    <dt className="col-4">Delivery Tax</dt>
                                    <dd className="col-8">{this.formatAmount(details.deliveryTax)}</dd>
                                    <dt className="col-4">Total</dt>
                                    <dd className="col-8">{this.formatAmount(details.grandTotal)}</dd>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}