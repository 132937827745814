import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import { getImageUrl } from "../Utils.js";

/**
 * Edits an image size pair.
 * @param {any} props
 */
export function EditImageSize(props) {
    // gets the ID from the URL, if editing an existing pair
    const { id } = useParams();
    // where the pair data resides
    const [data, setData] = useState({ imageRatio: 1 });
    // where the list of existing images resides
    const [existing, setExisting] = useState(null);

    useEffect(() => {
        async function getData() {
            // if ID is defined, get the existing pair
            if (id) {
                let response = await fetch(`/api/products/image-size?id=${id}`);
                let data = await response.json();
                setData(data);
            }

            // popuate the drop down lists with existing images
            let response = await fetch("/api/products/get-existing-images");
            let data = await response.json();
            setExisting(data);
        }

        getData();
    }, [id]);

    // renders the drop down lists with existing images
    const renderExisting = () => {
        if (!existing) return null;

        var result = existing.map((item, index) => {
            return (
                <option key={index} value={item}>{item}</option>
            )
        });

        result.unshift(<option key={-1}>Select image...</option>);
        return result;
    }

    // show the image on the page after selection is made on the drop downs
    const handleImageChange = (imageIndex, e) => {
        var image = e.target.value.replace(".jpg", "");
        var attribute = imageIndex === 0 ? "mainID" : "alternateID";
        setData({ ...data, [attribute]: image });
    }

    // changes the alternate image's size based on the slider value
    const handleSizeChange = (e) => {
        setData({ ...data, imageRatio: parseFloat(e.target.value) });
    }

    // saves this image size pair
    const save = async () => {
        const fetchOptions = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
        }

        // we are creating a new pair if the ID was not previously defined
        let createNew = !data.id;
        await fetch("/api/products/save-image-size", fetchOptions).then(async (response) => {
            if (response.status >= 400 && response.status < 600) {
                alert("Error");
                console.log(response);
                return;
            }

            let result = await response.text();
            console.log(result);
            if (result) {
                setData({ ...data, id: result });

                // update the URL to reflect the new pair's ID
                if (createNew) props.history.push('/products/images/edit-size/' + result);

                alert("Saved");
            }
            else alert("Error");
        }).catch((e) => {
            console.log(e);
        })
    }

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">{id ? "Edit" : "New"} Size</h1>
            </div>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <select className="form-control mb-3" value={data?.mainID + ".jpg"} onChange={(e) => handleImageChange(0, e)}>
                                {renderExisting()}
                            </select>
                        </div>
                        <div className="col">
                            <select className="form-control mb-3" value={data?.alternateID + ".jpg"} onChange={(e) => handleImageChange(1, e)}>
                                {renderExisting()}
                            </select>

                            <div className="form-group mb-3">
                                <input type="range" className="form-control-range" min={0.75} max={2} value={data.imageRatio} step={0.01} onChange={handleSizeChange}></input>
                                <div className="row">
                                    <span className="col">75%</span>
                                    <span className="col text-right">200%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-right align-self-end">
                            {data && <img src={getImageUrl(data.mainID, "400x400")} alt="" />}
                        </div>
                        <div className="col">
                            {data && <img src={getImageUrl(data.alternateID, "400x400")} alt="" style={{ width: data.imageRatio * 400 + "px" }} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow">
                <div className="card-body">
                    <button className="btn btn-primary mr-2" onClick={save}>Save</button>
                    <NavLink to="/products/images">Cancel</NavLink>
                </div>
            </div>
        </>
    );
}