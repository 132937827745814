import React, { Component } from 'react';
import { DateFilter, DateFilterContext, getDateFilterDate, formatDateInTitle } from './DateFilter'
import { sum, safeDivide } from '../Utils'

export class Reminders extends Component {
    static contextType = DateFilterContext;
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
            sendSuccess: false,
            sendFailed: false,
            sendFailMessage: null,
            isSending: false
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.sendReminders = this.sendReminders.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        // get the date. but if all year is requested, default back to january
        const date = getDateFilterDate(this.context);
        const response = await fetch(`api/customers/reminders?month=${date.month}&year=${date.year}`);
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }
    static renderDays(days) {
        return days.map(day =>
            <tr key={day.dateNumber} className={day.isWeekend ? "table-info" : ""}>
                <td>{day.dateNumber}</td>
                <td>{day.created}</td>
                <td>{day.scheduled}</td>
                <td>{day.orders}</td>
                <td>{(day.orderPercent * 100).toFixed(2)}</td>
            </tr>
        );
    }
    static renderWords(words) {
        return words.map(word =>
            <tr key={word.word}>
                <td>{word.word}</td>
                <td>{word.created}</td>
            </tr>
        );
    }
    static renderDaysTotal(days) {
        return (
            <tfoot>
                <tr>
                    <td>Total</td>
                    <td>{sum(days, 'created')}</td>
                    <td>{sum(days, 'scheduled')}</td>
                    <td>{sum(days, 'orders')}</td>
                    <td>{safeDivide(sum(days, 'created') * 100, sum(days, 'orders')).toFixed(2)}</td>
                </tr>
            </tfoot>);
    }
    handleDateChange(args) {
        this.setState(args, this.loadData);
    }
    /** Sends reminders that were due today. */
    async sendReminders() {
        this.setState({
            sendSuccess: false,
            sendFailed: false,
            sendFailMessage: null,
            isSending: true
        })

        const response = await fetch(`api/customers/send-reminders`);
        const data = await response.json();

        this.setState({ isSending: false });

        if (data.success) this.setState({ sendSuccess: true });
        else this.setState({
            sendFailed: false,
            sendFailMessage: data.message + " " + data.stackTrace
        })
    }
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">
                        Reminders in {formatDateInTitle(this.context.date)}
                    </h1>
                </div>
                <DateFilter onDateChange={this.handleDateChange}/>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Day</th>
                                    <th>Created</th>
                                    <th>Scheduled</th>
                                    <th>Orders</th>
                                    <th>% Orders</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.loading ? <tr><td colSpan="5">Loading...</td></tr> :
                                    Reminders.renderDays(this.state.data.days)}
                            </tbody>
                            {!this.state.loading && Reminders.renderDaysTotal(this.state.data.days)}
                        </table>
                    </div>
                </div>
                {this.state.data?.words?.length > 0 &&
                    <div className="card shadow mb-4">
                        <div className="card-header">Top Words</div>
                        <div className="card-body">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Word</th>
                                        <th>Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ? <tr><td colSpan="2">Loading...</td></tr> :
                                        Reminders.renderWords(this.state.data.words)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                <div className="text-center">
                    {this.state.isSending ?
                        <span>Sending...</span> :
                        <span onClick={this.sendReminders}>Send Reminders</span>
                    }
                </div>
                {this.state.sendSuccess && <div className="alert alert-success">Send successful.</div>}
                {this.state.sendFailed && <div className="alert alert-danger">Error: {this.state.sendFailMessage}</div>}
            </>
        );
    }
}