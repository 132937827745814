import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Orders } from './components/Orders';
import { OrdersDay } from './components/OrdersDay';
import { OrderDetail } from './components/OrderDetail';
import { Inquiries } from './components/Inquiries';
import { Reminders } from './components/Reminders';
import { AbandonedCarts } from './components/AbandonedCarts';
import { ErrorReport } from './components/ErrorReport';
import { TopProducts } from './components/TopProducts';
import { UploadExcel } from './components/UploadExcel';
import { Images } from './components/Images';
import { EditImageSize } from './components/EditImageSize';
import { UploadImages } from './components/UploadImages';
import { OrderTransmits } from './components/OrderTransmits';
import { TransmitOrders } from './components/TransmitOrders';
import { DateFilterContext } from './components/DateFilter';
import Login from './components/Login';

/** Gets the initial date of all reports. */
function getInitialDate() {
    let result = new Date();
    return { year: result.getFullYear(), month: result.getMonth() + 1 };
}

export default function App() {
    const [date, setDate] = useState(getInitialDate());
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    // handles the event when the date filter is change
    const changeDate = d => setDate(d);

    // if login component fires, set the authenticated flag to true
    const onLogin = () => setAuthenticated(true);

    // check to see if already logged in
    useEffect(() => {
        async function fetchData() {
            var response = await fetch("/api/auth/is-authenticated", { credentials: "include" });
            var data = await response.json();
            setAuthenticated(data);
            setLoading(false);
        }

        fetchData();
    }, [])

    if (!authenticated) {
        document.body.classList.add("bg-gradient-primary");

        // don't show the login screen if it's just loading
        if (loading) return <span></span>;

        return <Login onLogin={onLogin}></Login>
    }

    // logs out the user by sending the request to auth, then reload the login page
    const logout = async _ => {
        await fetch("/api/auth/logout");
        setAuthenticated(false);
    }

    // otherwise, show the normal app
    return (
        <DateFilterContext.Provider value={{ date: date, setDate: changeDate }}>
            <Layout onLogout={logout}>
                <Switch>
                    <Route exact path={['/', '/orders']} component={Orders} />
                    <Route path="/orders/day/:date" component={OrdersDay} />
                    <Route path="/orders/transmits/:date" component={OrderTransmits} />
                    <Route path="/orders/transmit" component={TransmitOrders} />
                    <Route path="/orders/:id" component={OrderDetail} />
                    <Route path="/customers/inquiries" component={Inquiries} />
                    <Route path="/customers/reminders" component={Reminders} />
                    <Route path="/customers/abandoned-carts" component={AbandonedCarts} />
                    <Route path="/errors" component={ErrorReport} />
                    <Route exact path="/products" component={TopProducts} />
                    <Route exact path="/products/upload" component={UploadExcel} />
                    <Route exact path="/products/images" component={Images} />
                    <Route path="/products/images/edit-size/:id?" component={EditImageSize} />
                    <Route path="/products/images/upload" component={UploadImages} />
                </Switch>
            </Layout>
        </DateFilterContext.Provider>
    );
}
