import React, { Component } from 'react';
import { Collapse, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

/** Sidebar navigation menu */
class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggle.bind(this);

        this.state = {
            path: window.location.pathname,
            preview: {}
        };
    }

    componentWillMount() {
        // updates the state whenever we navigate
        this.unlisten = this.props.history.listen((location) => {
            this.setState({
                path: location.pathname,
                preview: {},
            });
        })
    }

    componentWillUnmount() {
        this.unlisten();
    }
    /**
     * Toggles the open/close boxes when clicking on an item.
     * @param {any} node
     */
    toggle(node) {
        this.setState({
            // keep all the current preview values, but for the specific item we're toggling, set it to true
            // if it was never present, but flip its value if it was previously set
            preview: {
                ...this.state.preview,
                [node]: !this.state.preview[node],
            }
        });
    }
    /**
     * Determines if an item should be opened.
     * @param {any} path
     * @param {any} node
     */
    isOpen(path, node) {
        // should be opened if we're in the subsection as determined by the URL or preview is set to true
        return path.indexOf(node) === 1 || this.state.preview[node];
    }
    /**
     * Generates the inner menu item.
     * @param {any} link
     * @param {any} label
     * @param {boolean} supportSub - Whether this item should be activated if user is in its subpaths.
     */
    getInnerItem(link, label, supportSub) {
        const isActive = supportSub ? this.state.path.indexOf(link) === 0 : this.state.path === link;
        return <Link className={`collapse-item ${isActive ? "active" : ""}`} to={link}>{label}</Link>;
    }

    render() {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion">
                <span className="sidebar-brand d-flex">
                    <div className="sidebar-brand-text">Jackman&rsquo;s</div>
                </span>
                <hr className="sidebar-divider" />
                <NavItem active={this.state.path.indexOf("/orders", 0) === 0 || this.state.path === "/"}>
                    <NavLink tag={Link} to="/orders"><i className="fas fa-fw fa-receipt"></i> Orders</NavLink>
                </NavItem>
                <NavItem active={this.state.path === "/products"}>
                    <NavLink onClick={() => this.toggle("products")}><i className="fas fa-fw fa-shopping-bag"></i> Products</NavLink>
                    <Collapse isOpen={this.isOpen(this.state.path, "products")}>
                        <div className="bg-white py-2 collapse-inner rounded">
                            {this.getInnerItem("/products", "Top Products")}
                            {this.getInnerItem("/products/upload", "Upload")}
                            {this.getInnerItem("/products/images", "Images", true)}
                        </div>
                    </Collapse>
                </NavItem>
                <NavItem active={this.state.path === "/customers"}>
                    <NavLink onClick={() => this.toggle("customers")}><i className="fas fa-fw fa-users"></i> Customers</NavLink>
                    <Collapse isOpen={this.isOpen(this.state.path, "customers")}>
                        <div className="bg-white py-2 collapse-inner rounded">
                            {this.getInnerItem("/customers/inquiries", "Inquiries")}
                            {this.getInnerItem("/customers/reminders", "Reminders")}
                            {this.getInnerItem("/customers/abandoned-carts", "Abandoned Carts")}
                        </div>
                    </Collapse>
                </NavItem>
                <NavItem active={this.state.path === "/errors"}>
                    <NavLink tag={Link} to="/errors"><i className="fas fa-fw fa-exclamation-triangle"></i> Errors</NavLink>
                </NavItem>
                <hr className="sidebar-divider" />
                <NavItem>
                    <span className="nav-link" onClick={this.props.onLogout}><i className="fas fa-fw fa-sign-out-alt"></i> Logout</span>
                </NavItem>
            </ul>
        );
    }
}

export default withRouter(NavMenu);