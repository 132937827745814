import React, { Component } from 'react';
import { DateFilter, DateFilterContext, getDateFilterDate, formatDateInTitle } from './DateFilter'

const TopProductCount = 16;
const BottomColumnCount = 4;

export class TopProducts extends Component {
    static contextType = DateFilterContext;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
        }

        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        const date = getDateFilterDate(this.context, true);
        const response = await fetch(`api/products/top?year=${date.year}&month=${date.month}`);
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }
    static renderItems(items) {
        return items.map(item =>
            <div key={item.rank} className="col-md text-center">
                <a href={`https://www.jackmans.com/products/${item.code}`}>
                    <img src={`https://www.jackmans.com${item.imageUrl}`} alt="" />
                </a><br/>
                <h5>{item.name} <small className="text-muted">({item.code})</small></h5>
                {item.numberSold} sold <small className="text-muted">(#{item.rank})</small><br />                
                Average price: ${item.averagePrice.toFixed(2)}
            </div>
        );
    }
    handleDateChange(args) {
        this.setState(args, this.loadData);
    }
    render() {
        return (
            <>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Products Sold in {formatDateInTitle(this.context.date)}</h1>
                </div>
                <DateFilter onDateChange={this.handleDateChange} includeAllYear={true}/>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <div className="row">
                            {this.state.loading ? "Loading..." :
                                this.state.data.length ? TopProducts.renderItems(this.state.data) :
                                    "No products sold this month."}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}