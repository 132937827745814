import React, { useState } from 'react';
import DropzoneComponent from 'react-dropzone-component';

import './dropzone.min.css';
import 'react-dropzone-component/styles/filepicker.css'

export function UploadExcel() {
    const [uploadComplete, setUploadComplete] = useState(false);
    const [applyComplete, setApplyComplete] = useState(false);
    const [applying, setApplying] = useState(false);
    const [errors, setErrors] = useState(null);

    // refresh the database with the uploaded Excel
    const apply = async () => {
        setApplyComplete(false);
        setErrors(null);
        setUploadComplete(false);
        setApplying(true);

        var response = await fetch("/api/products/apply-excel", { method: "POST" });
        var data = await response.json();
        if (data && data.success) setApplyComplete(true);
        else setErrors(data);

        setApplying(false);
    }

    // configure the dropzone component
    const dzConfig = {
        iconFiletypes: ['.xlsx'],
        showFiletypeIcon: true,
        postUrl: "/api/products/upload-excel"
    };

    // configure the dropzone js component
    const djsConfig = {
        acceptedFiles: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    }

    let dropzone;
    const dzEventHandlers = {
        init: dz => dropzone = dz,
        queuecomplete: () => {
            // allow database to be refreshed if the upload is complete. error message otherwise
            setErrors(false);
            setUploadComplete(false);

            var lastFile = dropzone?.files && dropzone.files[dropzone.files.length - 1]
            if (lastFile.status === "success") setUploadComplete(true);
            else setErrors(["File rejected. Please upload an .xlsx file."]);
        }
    }

    // show error messages if found
    const errorMessage = errors && errors.length > 0 &&
        <div className="alert alert-danger my-4">
            There were errors processing the file. Please check the file and try again:
            <ul>
                {errors.map((item, index) =>
                    <li key={index}>{item}</li>
                )}
            </ul>
        </div>;

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Upload Excel</h1>
            </div>
            <div className="card shadow mb-4">
                <div className="card-body">
                    <DropzoneComponent config={dzConfig} djsConfig={djsConfig} eventHandlers={dzEventHandlers} />
                    {errorMessage}
                    {applyComplete && <div className="alert alert-success my-4">Database refreshed.</div>}
                    {applying && <div className="alert alert-info my-4">Updating database...</div>}
                    {uploadComplete &&
                        <>
                            <div className="alert alert-info my-4">File uploaded. Click update database below to process it.</div>
                            <button className="btn btn-primary" onClick={apply}>Update Database</button>
                        </>
                    }
                </div>
            </div>
        </>
    );
}