import React, { Component } from 'react';
import { DateFilter, DateFilterContext, getDateFilterDate } from './DateFilter'
import { safeDivide } from '../Utils'

export class AbandonedCarts extends Component {
    static contextType = DateFilterContext;
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loading: true,
        }

        this.handleDateChange = this.handleDateChange.bind(this);
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        // get the date. but if all year is requested, default back to january
        const date = getDateFilterDate(this.context);
        const response = await fetch(`api/customers/abandoned-carts?month=${date.month}&year=${date.year}`);
        const data = await response.json();
        this.setState({ data: data, loading: false });
    }
    handleDateChange(args) {
        this.setState(args, this.loadData);
    }
    static renderStats(data) {
        return (
            <div className="row">
                <dt className="col-3">Pending</dt>
                <dd className="col-9">{data.pending}</dd>
                <dt className="col-3">No email</dt>
                <dd className="col-9">{data.noEmail}</dd>
                <dt className="col-3">Contacted</dt>
                <dd className="col-9">{data.contacted}</dd>
                <dt className="col-3">Recovered</dt>
                <dd className="col-9">{data.recovered}</dd>
                <dt className="col-3">Recovered %</dt>
                <dd className="col-9">{(safeDivide(data.recovered, data.contacted + data.noEmail) * 100).toFixed(2)}%</dd>
            </div>);
    }
    render() {
        return (
            <div>
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                    <h1 className="h3 mb-0 text-gray-800">Abanonded Cart</h1>
                </div>
                <DateFilter onDateChange={this.handleDateChange} month={this.state.month} year={this.state.year} />
                <div className="card shadow mb-4">
                    <div className="card-body">
                        {this.state.loading ? "Loading..." : AbandonedCarts.renderStats(this.state.data)}
                    </div>
                </div>
            </div>
        );
    }
}