import React, { Component } from 'react';
import Moment from 'moment';

export const DateFilterContext = React.createContext();

export class DateFilter extends Component {
    static contextType = DateFilterContext;
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }
    static getYears() {
        let result = [];
        const currentYear = (new Date()).getFullYear();
        for (let i = 2009; i <= currentYear; ++i) {
            result.push(i);
        }

        return result;
    }
    handleChange(event, option) {
        var date = this.context.date;
        var value = parseInt(event.target.value);
        date = option === "month" ? { year: date.year, month: value } : { year: value, month: date.month };

        this.context.setDate(date);
        this.props.onDateChange(date);
    }
    render() {
        let date = this.context.date;

        let years = DateFilter.getYears().map((item, i) => {
            return (
                <option key={i} value={item}>{item}</option>
            )
        });

        return (
            <div className="card shadow mb-4">
                <div className="card-body">
                    <div className="form-inline">
                        <select className="form-select" value={date.month} onChange={(e) => this.handleChange(e, 'month')}>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                            {this.props.includeAllYear && <option value="-1">All Year</option>}
                        </select>
                        <select className="form-select" value={date.year} onChange={(e) => this.handleChange(e, 'year')}>
                            {years}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}
/**
 * Gets the date from a component's context.
 * @param {any} context - The component's context.
 * @param {any} enableFullYear - Whether the full year option is enabled for the component.
 */
export function getDateFilterDate(context, enableFullYear) {
    // get the date. but if all year is requested, default back to january
    const date = context.date;
    if (!enableFullYear && date.month === -1) return { year: date.year, month: 1 };
    return context.date;
}
/**
 * Formats the date object on the H1 title of pages, considering -1 as the full year.
 * @param {object} date - The date object to format with (not to be confused with JavaScript's date).
 */
export function formatDateInTitle(date) {
    if (date.month === -1) return date.year;
    var dateObject = new Date(date.year, date.month - 1, 1);
    return Moment(dateObject).format("MMMM yyyy");
}